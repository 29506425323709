﻿import "lazysizes";

/**
 * Extending Lasysize to support background images
 * */

document.addEventListener("lazybeforeunveil", (e): void => {
  const el = e.target as HTMLElement;
  const bg = el.getAttribute("data-bg");
  if (bg) {
    const forStyle = el.getAttribute("for-style");
    if (!(forStyle && forStyle.length > 0)) {
      el.style.backgroundImage = `url(${bg})`;
      return;
    }

    const style = `${forStyle}{background-image: url(${bg}) }`;

    const css = document.createElement("style");
    css.type = "text/css";
    css.appendChild(document.createTextNode(style));
    el.parentNode.insertBefore(css, el);
  }
});
